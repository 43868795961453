import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { showSnack } from 'react-redux-snackbar';
import { useDispatch } from 'react-redux';
import { postApi, putApi } from '../../services/api';
import { useEffect } from 'react';
import { Space, Row, Col, Button, Drawer, Form, Input, Select, Divider, Popover } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { debounce, filter } from 'lodash';

const Styles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '50%',
		height: '100vh',
		transform: 'translate(-20%, -50%)',
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
};

const EditModal = ({ filteredData, editModalOpen, openEditModal, closeEditModal, updateId }) => {
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const [titleItems, setTitleItems] = useState(['Mr.', 'Mrs.']);
	const [codeItems, setCodeItems] = useState(['+91']);
	const [code, setCode] = useState('');
	const [officeLocation, setOfficeLocations] = useState([]);
	const [title, setTitle] = useState('');
	const [form] = Form.useForm();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		getValues,
	} = useForm();

	useEffect(() => {
		console.log('filterData2', filteredData);

		if (filteredData) {
			form.setFieldsValue({
				branchId: filteredData.branchId,
				address1: filteredData.address1,
				address2: filteredData.address2,
				address3: filteredData.address3,
				pincode: filteredData.pincode,
				city: filteredData.city,
				state: filteredData.state,
				contact: filteredData.contact,
				poc: filteredData.poc,
			});
			console.log('filterData3', filteredData);
			// reset({
			// 	branchId: filteredData.branchId,
			// 	address1: filteredData.address1,
			// 	address2: filteredData.address2,
			// 	address3: filteredData.address3,
			// 	pincode: filteredData.pincode,
			// 	city: filteredData.city,
			// 	state: filteredData.state,
			// 	contact: filteredData.contact,
			// });
			//  Object.entries(filteredData).forEach(([name, value]) => setValue(name, value));
		}
	}, [filteredData, setValue]);

	console.log('filteredData', filteredData);
	console.log('updateId', updateId);

	const updateHandler = async (data) => {
		// delete data[0];
		//console.log("data", filter);
		// data["locationId"] = filteredData[0]._id;
		// console.log("data", filter);

		const updateData = { locationId: updateId, ...data };
		try {
			const resp = await putApi(updateData, '/company/locations');
			if (resp.type === 'success') {
				console.log(resp);
				// setBranchDetails(resp.location);

				// reset(initialState);
				dispatch(
					showSnack('COMPANY_BRANCH_ADDED', {
						label: 'Company Branch Updated Successfully',
						timeout: 7000,
					})
				);
				closeEditModal();
			} else {
				return dispatch(
					showSnack('COMPANY_BRANCH_ADDED', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (error) {
			return dispatch(
				showSnack('COMPANY_BRANCH_ERROR', {
					label: error && error.message,
					timeout: 7000,
				})
			);
		}
	};

	const onNameChange = (event) => {
		setTitle(event.target.value);
	};
	const addItem = (e) => {
		e.preventDefault();
		setTitleItems(title ? [...titleItems, title] : [...titleItems]);
		setTitle('');
		setTimeout(() => {
			inputRef.current?.focus();
		}, 0);
	};

	const searchBranch = debounce(async (filterText) => {
		const resp = await postApi({ filterText }, `/company/locations/search`);
		if (resp.type === 'success') {
			setOfficeLocations(
				resp.offliceLocations.map((el) => ({
					label: el.branchId,
					value: el._id,
				}))
			);
		}
	}, 1000);

	const onCodeChange = (event) => {
		setCode(event.target.value);
	};
	const addItemCode = (e) => {
		e.preventDefault();
		setCodeItems(code ? [...codeItems, code] : [...codeItems]);
		setCode('');
		setTimeout(() => {
			inputRef.current?.focus();
		}, 0);
	};
	return (
		<div>
			<Drawer
				destroyOnClose={true}
				title="Edit Company Branch"
				width={800}
				onClose={closeEditModal}
				open={editModalOpen}
				styles={{
					body: {
						paddingBottom: 80,
					},
				}}
				extra={
					<Space className="mobile-hide-btns">
						<Button onClick={closeEditModal}>Cancel</Button>
						<Button
							htmlType="submit"
							type="primary"
							onClick={() => form.submit()}
							style={{ backgroundColor: '#33D7DB', border: 'none', color: 'black' }}
						>
							Submit
						</Button>
					</Space>
				}
			>
				<div className="company-branch-div">
					<Form form={form} onFinish={updateHandler} layout="vertical" hideRequiredMark>
						<div className="companyInfo-div" style={{ display: 'flex' }}>
							<div className="company-details" style={{ paddingRight: '30px' }}>
								<h3>Company Info</h3>
								<p>Assign unique ID to your company branches for easier tracking</p>
							</div>
							<div style={{ float: 'right' }}>
								{/* <Row gutter={12}> */}
								{/* <Col span={19}> */}

								<Form.Item
									name="branchId"
									label="Branch ID"
									rules={[
										{
											required: true,
											message: 'Provide Branch ID',
										},
									]}
								>
									<Input disabled style={{ width: '380px' }} placeholder="Provide Branch ID" />
								</Form.Item>
								{/* <Form.Item
									name="parentBranch"
									label="Parent Branch"
									rules={[
										{
											required: true,
											message: 'Provide Parent Branch',
										},
									]}
								>
									<Select
										allowClear
										filterOption={false}
										options={officeLocation}
										placeholder="Search Parent Branch"
										showSearch
										onSearch={(val) => {
											searchBranch(val);
										}}
									/>
								</Form.Item> */}
								{/* </Col> */}
								{/* </Row> */}
							</div>
						</div>
						<div className="addressInfo-div" style={{ display: 'flex' }}>
							<div className="address-info-details">
								<h3>Address Info</h3>
								<p>Enter the branch Details </p>
							</div>
							<div>
								<Row gutter={12}>
									<Col span={12}>
										<Form.Item
											name="address1"
											label="Building No."
											// rules={[
											// 	{
											// 		required: true,
											// 		message: 'Provide Building No.',
											// 	},
											// ]}
										>
											<Input placeholder="Provide Building No." />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											name="address2"
											label="Street"
											// rules={[
											// 	{
											// 		required: true,
											// 		message: 'Provide Street',
											// 	},
											// ]}
										>
											<Input placeholder="Provide Street" />
										</Form.Item>
									</Col>
								</Row>
								{/* <Col span={12}> */}
								<Form.Item
									name="address3"
									label="Locality/Area"
									// rules={[
									// 	{
									// 		required: true,
									// 		message: 'Provide Locality/Area',
									// 	},
									// ]}
								>
									<Input style={{ width: '100%' }} placeholder="Provide Locality/Area" />
								</Form.Item>
								{/* </Col> */}
								<Row gutter={16}>
									<Col span={12}>
										<Form.Item
											name="pincode"
											label="Pincode"
											// rules={[
											// 	{
											// 		required: true,
											// 		message: 'Provide Pincode',
											// 	},
											// ]}
										>
											<Input placeholder="Provide Pincode" />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											name="city"
											label="City"
											// rules={[
											// 	{
											// 		required: true,
											// 		message: 'Provide City',
											// 	},
											// ]}
										>
											<Input placeholder="Provide City" />
										</Form.Item>
									</Col>
								</Row>
								{/* <Col span={12}> */}
								<Form.Item
									name="state"
									label="State"
									// rules={[
									// 	{
									// 		required: true,
									// 		message: 'Provide State',
									// 	},
									// ]}
								>
									<Input style={{ width: '100%' }} placeholder="Provide State" />
								</Form.Item>
								{/* </Col> */}
							</div>
						</div>
						<div className="contactInfo-div" style={{ display: 'flex' }}>
							<Form.List name="poc">
								{(fields, { add, remove }) => {
									console.log(fields);

									return (
										<>
											<div style={{ paddingRight: '20px' }} className="company-details">
												<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
													<h3 style={{ marginBottom: '0px' }}>Contact Info</h3>
													<Popover content={'Add New'}>
														<PlusCircleOutlined onClick={() => add('ok', 2)} />
													</Popover>
												</div>
												<p>Enter the branch contact person details </p>
											</div>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												{fields.map(({ key, name, ...restField }) => (
													<div
														key={key}
														style={{
															width: '380px',
															marginTop: '20px',
															position: 'relative',
														}}
													>
														<Popover content={'Remove'}>
															<MinusCircleOutlined
																style={{
																	position: 'absolute',
																	right: '0px',
																	cursor: 'pointer',
																	zIndex: '2000',
																}}
																onClick={() => remove(name)}
															/>
														</Popover>
														<div className="name-div">
															<Col span={6}>
																<Form.Item
																	{...restField}
																	name={[name, 'title']}
																	label="Title"
																>
																	<Select
																		placeholder="Title"
																		dropdownRender={(menu) => (
																			<>
																				{menu}
																				<Divider
																					style={{
																						margin: '8px 0',
																					}}
																				/>
																				<Space
																					style={{
																						display: 'flex',
																						flexDirection: 'column',
																					}}
																				>
																					<Input
																						placeholder="Title"
																						ref={inputRef}
																						value={title}
																						onChange={onNameChange}
																						onKeyDown={(e) =>
																							e.stopPropagation()
																						}
																					/>
																					<Button
																						type="primary"
																						onClick={addItem}
																					>
																						Add Title
																					</Button>
																				</Space>
																			</>
																		)}
																		options={titleItems.map((item) => ({
																			label: item,
																			value: item,
																		}))}
																	/>
																</Form.Item>
															</Col>
															<Form.Item
																{...restField}
																name={[name, 'firstName']}
																label="First Name"
															>
																<Input placeholder="First Name" />
															</Form.Item>
															<Form.Item
																{...restField}
																name={[name, 'lastName']}
																label="Last Name"
															>
																<Input placeholder="Last Name" />
															</Form.Item>
														</div>
														<div className="phone-no-div">
															<Col span={7}>
																<Form.Item
																	{...restField}
																	label="Country Code"
																	name={[name, 'countryCode']}
																>
																	<Select
																		placeholder="Code"
																		dropdownRender={(menu) => (
																			<>
																				{menu}
																				<Divider
																					style={{
																						margin: '8px 0',
																					}}
																				/>
																				<Space
																					style={{
																						display: 'flex',
																						flexDirection: 'column',
																					}}
																				>
																					<Input
																						placeholder="Code"
																						ref={inputRef}
																						value={code}
																						onChange={onCodeChange}
																						onKeyDown={(e) =>
																							e.stopPropagation()
																						}
																					/>
																					<Button
																						type="primary"
																						onClick={addItemCode}
																					>
																						Add Code
																					</Button>
																				</Space>
																			</>
																		)}
																		options={codeItems.map((item) => ({
																			label: item,
																			value: item,
																		}))}
																	/>
																</Form.Item>
															</Col>
															<Col span={16}>
																<Form.Item
																	{...restField}
																	name={[name, 'phone']}
																	label="Contact Number"
																>
																	<Input placeholder="Provide Phone Number" />
																</Form.Item>
															</Col>
														</div>
														<hr />
													</div>
												))}
											</div>
										</>
									);
								}}
							</Form.List>
						</div>
						{/* <div className='contactInfo-div' style={{ display: "flex" }}>
							<div style={{ paddingRight: "20px" }} className="company-details" >
								<h3>Contact Info</h3>
								<p>Enter the branch contact person details </p>
							</div>
							<div style={{ width: "380px" }}>
								<div className='name-div'>
									<Col span={6}>
										<Form.Item
											name={['contact', 'title']}
											label="Title"
										>
											<Select
												placeholder="Title"
												dropdownRender={(menu) => (
													<>
														{menu}
														<Divider style={{
															margin: '8px 0',
														}} />
														<Space
															style={{
																display: "flex",
																flexDirection: "column",
															}}
														>
															<Input
																placeholder="Title"
																ref={inputRef}
																value={title}
																onChange={onNameChange}
																onKeyDown={(e) => e.stopPropagation()}
															/>
															<Button type="primary" onClick={addItem}>
																Add Title
															</Button>
														</Space>
													</>
												)}
												options={titleItems.map((item) => ({
													label: item,
													value: item,
												}))}
											/>
										</Form.Item>
									</Col>
									<Form.Item
										name={['contact', 'firstName']}
										label="First Name"
									>
										<Input
											placeholder="First Name"
										/>
									</Form.Item>
									<Form.Item
										name={['contact', 'lastName']}
										label="Last Name"
									>
										<Input
											placeholder="Last Name"
										/>
									</Form.Item>
								</div>
								<div className='phone-no-div'>
									<Col span={7}>
										<Form.Item
											label='Country Code'
											name={['contact', 'countryCode']}
										>
											<Select
												placeholder="Code"
												dropdownRender={(menu) => (
													<>
														{menu}
														<Divider
															style={{
																margin: '8px 0',
															}}
														/>
														<Space
															style={{
																display: "flex",
																flexDirection: "column"
															}}
														>
															<Input
																placeholder="Code"
																ref={inputRef}
																value={code}
																onChange={onCodeChange}
																onKeyDown={(e) => e.stopPropagation()}
															/>
															<Button type='primary' onClick={addItemCode}>
																Add Code
															</Button>
														</Space>
													</>
												)}
												options={codeItems.map((item) => ({
													label: item,
													value: item,
												}))}
											/>
										</Form.Item>
									</Col>
									<Col span={16}>
										<Form.Item
											name={['contact', 'phone']}
											label="Contact Number"
										>
											<Input
												placeholder="Provide Phone Number"
											/>
										</Form.Item>
									</Col></div>
							</div>
						</div> */}
						<div className="drawer-mobile-btns">
							<Button
								style={{ height: '40px', width: '100px', borderRadius: '3px' }}
								size="large"
								onClick={closeEditModal}
							>
								Cancel
							</Button>
							<Button
								type="primary"
								onClick={() => form.submit()}
								size="large"
								className="ant-design-button"
								style={{ height: '40px', width: '100px' }}
							>
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</Drawer>
			<Modal
				// isOpen={editModalOpen}
				onRequestClose={closeEditModal}
				style={Styles}
				ariaHideApp={false}
				contentLabel="Add Company Branch Modal"
			>
				<div className="modal-form-container">
					<div className="company-info">
						<div className="company-details">
							<h3>Company Info</h3>
							<p>Assign unique ID to your company branches for easier tracking</p>
						</div>
						<div className="company-id">
							<div className="branch">
								<span>Branch ID</span>

								<input
									type="text"
									{...register('branchId', {
										required: true,
									})}
									//  value={filteredData.branchId}
								/>
							</div>
						</div>
					</div>
					<div className="address-info">
						<div className="address-info-details">
							<h3>Address Info</h3>
							<p>Enter the branch Details </p>
						</div>
						<div className="address-details">
							<div className="street-container">
								<div className="branch">
									<span>Building No.</span>
									<input
										type="text"
										{...register('address1', {
											required: true,
										})}
									/>
								</div>

								<div className="branch">
									<span>Street</span>
									<input
										type="text"
										{...register('address2', {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="area-container">
								<div className="branch">
									<span>Locality/Area</span>
									<input
										type="text"
										{...register('address3', {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="street-container">
								<div className="branch">
									<span>Pincode</span>
									<input
										type="text"
										{...register('pincode', {
											required: true,
										})}
									/>
								</div>
								<div className="branch">
									<span>City</span>
									<input
										type="text"
										{...register('city', {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="area-container">
								<div className="branch">
									<span>State</span>
									<input
										type="text"
										{...register('state', {
											required: true,
										})}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="contact-info">
						<div className="company-details">
							<h3>Contact Info</h3>
							<p>Enter the branch contact person details </p>
						</div>
						<div className="info-container">
							<div className="name-container">
								<select
									className="select-one"
									{...register('contact.title', {
										required: true,
									})}
								>
									<option value="Mr">Mr</option>
									<option value="Mrs">Mrs</option>
								</select>
								<div className="branch">
									<span>First Name</span>
									<input
										className="contact"
										type="text"
										{...register('contact.firstName', {
											required: true,
										})}
									/>
								</div>
								<div className="branch">
									<span>Last Name</span>
									<input
										className="contact"
										type="text"
										{...register('contact.lastName', {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="mobile-container">
								<select
									className="select-one"
									{...register('contact.countryCode', {
										required: true,
									})}
								>
									<option value="+91">+91</option>
								</select>
								<div className="branch">
									<span>Contact Number</span>
									<input className="contact" type="text" {...register('contact.phone')} />
								</div>
							</div>
						</div>
					</div>
					<div className="btn-container">
						<button onClick={closeEditModal} className="cancel-btn">
							Cancel
						</button>
						<button className="save-btn" onClick={handleSubmit(updateHandler)}>
							Update
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default EditModal;
