import { postApi } from '../../services/api';
import { showSnack } from 'react-redux-snackbar';
import { downloadFromURL } from '../../utils/downloadFromURL';
import { triggerShowSnack } from '../../utils/snack';
import { assetTagUrl } from '../../services/constants';

export const fetchFilterData = async (allFilterInfo, dispatch, eventType = 'download') => {
	try {
		const resp = await postApi({ filter: allFilterInfo, eventType }, '/assets/download-assets', assetTagUrl);
		if (resp.type === 'success') {
			if (eventType === 'download') {
				downloadFromURL(resp);
			} else {
				if (resp.assets && resp.assets.length > 0) {
					return resp.assets;
				} else {
					dispatch(
						showSnack('FILE_UPLOAD_ERROR', {
							label: 'Nothing to show on filters',
							timeout: 7000,
						})
					);
				}
			}
		} else {
			dispatch(
				showSnack('FILE_UPLOAD_ERROR', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}
	} catch (e) {
		console.error(e);
		dispatch(
			showSnack('FILE_UPLOAD_ERROR', {
				label: 'Something went wrong',
				timeout: 7000,
			})
		);
	}
};

export const showFilterData = async (
	allFilterInfo,
	dispatch,
	eventType = 'show',
	searchTextWithFilter,
	pageNumber,
	limit
) => {
	try {
		const resp = await postApi(
			{ filter: allFilterInfo, eventType, searchTextWithFilter, pageNumber, limit },
			'/assets/view-assets',
			assetTagUrl
		);
		if (resp.type === 'success') {
			if (eventType === 'download') {
				downloadFromURL(resp);
			} else {
				if (resp.assets && resp.assets.length > 0) {
					// if (eventType === 'show') {
					// 	dispatch({ type: 'SET_TOTAL_ASSET_COUNT', payload: resp.totalAssetsCount });
					// }
					return { assetList: resp.assets, totalAssets: resp.totalAssetsCount };
				} else {
					dispatch(
						showSnack('FILE_UPLOAD_ERROR', {
							label: 'Nothing to show on filters',
							timeout: 7000,
						})
					);
				}
			}
		} else {
			dispatch(
				showSnack('FILE_UPLOAD_ERROR', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}
	} catch (e) {
		console.error(e);
		dispatch(
			showSnack('FILE_UPLOAD_ERROR', {
				label: 'Something went wrong',
				timeout: 7000,
			})
		);
	}
	return { assetList: [], totalAssets: 0 };
};

export const generateAssetTag = async (allFilterInfo, dispatch, eventType = 'barcode-create') => {
	try {
		let token = localStorage.getItem('jwt-token');
		const rawResponse = await fetch(`${assetTagUrl}/assets/generate-barcodes`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				authorization: 'Bearer ' + token,
				'is-az': 'yes',
			},
			credentials: 'include',
			body: JSON.stringify({ filter: allFilterInfo, eventType }),
		});
		const resp = await rawResponse.json();
		if (resp.type === 'success') {
			dispatch(
				showSnack('INVALID_HEALTH', {
					label: 'Asset Tag Generated Successfully',
					timeout: 7000,
				})
			);
		} else {
			dispatch(
				showSnack('INVALID_HEALTH', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}
		// triggerShowSnack(dispatch, resp.message, 'BARCODE_GENERATION');
	} catch (e) {
		console.error(e);
		dispatch(
			showSnack('FILE_UPLOAD_ERROR', {
				label: 'Something went wrong',
				timeout: 7000,
			})
		);
	}
};
