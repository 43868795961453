const initialState = {
	isSearched: false,
	searchWithFilterText: null,
	searchText: '',
	searchAssets: [],
	filterInfo: [],
	pagination: { pageNumber: 1, limit: 10, total: 0 },
};
function searchReducer(state = initialState, action) {
	switch (action.type) {
		case 'inputSearched':
			return { ...state, isSearched: true };
		case 'inputSearchedWithFilter':
			return { ...state, searchWithFilterText: action.payload };
		case 'inputCleared':
			return { ...state, isSearched: false };
		case 'updatedSearchText':
			return { ...state, searchText: action.payload };
		case 'saveFilterInfo':
			return { ...state, filterInfo: action.payload };
		case 'saveSearchResult':
			return { ...state, searchAssets: action.payload };
		case 'setPageNumber':
			return { ...state, pagination: { ...state.pagination, pageNumber: action.payload } };
		case 'setLimitInPage':
			return { ...state, pagination: { ...state.pagination, limit: action.payload } };
		case 'setTotalSearchAssets':
			return { ...state, pagination: { ...state.pagination, total: action.payload } };
		case 'removeAsset':
			return {
				...state,
				searchAssets: state.searchAssets.filter((el) => {
					return el._id !== action.payload;
				}),
			};
		default:
			return { ...state };
	}
}

export default searchReducer;
