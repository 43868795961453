export const serverUrl = process.env.REACT_APP_server;
export const assetTagUrl = process.env.REACT_APP_ASSET_TAG_SERVER;

export const DEFAULT_PERMISSION = [
	{
		obj: 'on_asset',
		permList: [
			{ obj: 'asset', actions: ['create', 'view', 'delete', 'edit'], label: 'Asset View' },
			{
				obj: 'asset_update_custom',
				actions: ['create', 'delete', 'edit'],
				label: 'Update Asset Custom Field',
			},
			{ obj: 'audit', actions: ['view', 'create'], label: 'Asset Audit' },
			{ obj: 'image', actions: ['view', 'create'], label: 'Asset Image' },
			{ obj: 'activity', actions: ['view'], label: 'Asset Activity' },
			{ obj: 'depreciation', actions: ['view', 'create'], label: 'Asset Depreciation' },
			{ obj: 'asset_assign_unassign', actions: ['create'], label: 'Asset Assign/UnAssign' },
			{ obj: 'archive_lit', actions: ['view'], label: 'Asset Archive' },
			{ obj: 'comment', actions: ['edit', 'view'], label: 'Asset Comment' },
			{ obj: 'programs_view', actions: ['view'], label: 'Programs Installed' },
			{ obj: 'ram_view', actions: ['view'], label: 'Asset RAM View' },
			{ obj: 'location_view', actions: ['view'], label: 'Asset Location View' },
			{ obj: 'users_view', actions: ['view'], label: 'Asset Users View' },
			{ obj: 'health_view', actions: ['view'], label: 'Asset Health View' },
			{ obj: 'report_generate', actions: ['create'], label: 'Asset Report' },
			{ obj: 'generate_tag', actions: ['create'], label: 'Asset Tag ' },
			{ obj: 'filter', actions: ['view'], label: 'Asset Filter' },
		],
	},
	{
		obj: 'on_organsiation',
		permList: [
			{ obj: 'presonal_information', actions: ['view', 'edit'], label: 'Personal Information' },
			{ obj: 'company_information', actions: ['edit', 'view'], label: 'Organisation Information' },
			{
				obj: 'company_branches',
				actions: ['create', 'edit', 'view', 'delete'],
				label: 'Organisation Branches',
			},
			{ obj: 'asset_tag_setup', actions: ['edit', 'view'], label: 'Organisation Setup Tag' },
			{ obj: 'asset_types', actions: ['view', 'create', 'delete'], label: 'Organisation Asset Types' },
			{ obj: 'purchase_order', actions: ['view', 'create', 'delete'], label: 'Organisation Invoices' },
			{ obj: 'admin_setup', actions: ['create', 'view'], label: 'Oraganisation Admin' },
			{
				obj: 'employee_setup',
				actions: ['create', 'view', 'edit', 'delete'],
				label: 'Organisation Employee',
			},
			{ obj: 'agent_computers', actions: ['view'], label: 'Organisation Agent' },
			{ obj: 'activity', actions: ['view'], label: 'Activity' },
			{ obj: 'agent_computer_report', actions: ['create'], label: 'Organisation Agent Report' },
			{ obj: 'download_agent', actions: ['create'], label: 'Organisation Download Agent Report' },
		],
	},
];

export const ASSET_STATE_OPTIONS = [
	{
		label: <span>with Employee</span>,
		title: 'with Employee',
		options: [{ value: 'Assigned', label: 'Assigned', description: 'Asset is actively being used.' }],
	},
	{
		label: <span>with IT</span>,
		title: 'with IT',
		options: [
			{ value: 'In Inventory', label: 'In Inventory', description: 'Asset is in stock but not yet assigned.' },
			{ value: 'In Repair', label: 'In Repair', description: 'Asset is currently being repaired or serviced.' },
			{
				value: 'Retired',
				label: 'Retired',
				description: 'Asset is no longer in use but retained for record purposes.',
			},
			{ value: 'Dead', label: 'Dead', description: 'Asset is non-functional and beyond repair.' },
			{
				value: 'Damaged',
				label: 'Damaged',
				description: 'Asset is physically damaged but potentially repairable.',
			},
			{
				value: 'Pending Disposal',
				label: 'Pending Disposal',
				description: 'Asset is marked for disposal but hasn’t been disposed yet.',
			},
		],
	},
	{
		label: <span>Others</span>,
		title: 'Others',
		options: [{ value: 'Disposed', label: 'Disposed', description: 'Asset has been disposed of or recycled.' }],
	},
];

export function getDescription(selected) {
	console.log(selected, 'selected');
	for (let x = 0; x < ASSET_STATE_OPTIONS.length; x += 1) {
		for (let y = 0; y < ASSET_STATE_OPTIONS[x].options.length; y += 1) {
			if (ASSET_STATE_OPTIONS[x].options[y].value === selected) {
				return ASSET_STATE_OPTIONS[x].options[y].description;
			}
		}
	}
}

export const ASSET_STATE_OPTIONS2 = [
	{ value: 'In Inventory', label: 'In Inventory', disabled: true },
	{ value: 'In Repair', label: 'In Repair' },
	{ value: 'Retired', label: 'Retired' },
	{ value: 'Disposed', label: 'Disposed' },
	{ value: 'Dead', label: 'Dead' },
	{ value: 'Lost', label: 'Lost' },
	{ value: 'Pending Disposal', label: 'Pending Disposal' },
	{ value: 'Damaged', label: 'Damaged' },
	{ value: 'In Use', label: 'In Use', disabled: true },
];
