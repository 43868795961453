import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './index.css';
import { AiFillStar, AiOutlineDelete } from 'react-icons/ai';
import { BiLinkExternal } from 'react-icons/bi';
import { FiEdit2 } from 'react-icons/fi';
// import Drawer from '@mui/material/Drawer';
import { Drawer, Table } from 'antd';
import TemporaryDrawer from '../../screens/DeviceDetails';
import _ from 'lodash';
import { getAssets, getAssetsPagination, RESET_ASSETS_TABLE, UPDATE_ASSET_INFO } from '../../redux/actions';
import { differenceInDays } from 'date-fns';
import AssetsTable from '../AssetsTAble';
import { FilterAssetsColumn } from '../FilterAssets';
import FooterDashboard from '../FooterDashboard';
import HeaderComponent from '../Header';
import { getApi, postApi } from '../../services/api';
import { BsSearch, BsFillCloudUploadFill } from 'react-icons/bs';

const ArchieveStockScreen = function () {
	const listInnerRef = useRef();
	const [achieveList, setArchieveStock] = useState([]);
	const [filterText, setFilterText] = useState('');
	const assets = useSelector((state) => state.appreducer.assets);
	const resetAssetsTable = useSelector((state) => state.appreducer.resetAssetsTable);
	const arrayOfChosenOptions = useSelector((state) => state.appreducer.assetsTableColumns);
	const [selectedAsset, setSelectedAsset] = useState({});
	const dispatch = useDispatch();
	const [state, setState] = React.useState({
		right: false,
	});
	//console.log(arrayOfChosenOptions);

	useEffect(() => {
		const getInstockData = async function () {
			const resp = await getApi('/assetze/asset-update/deleted-assets');
			if (resp.type === 'success') {
				setArchieveStock(resp.archievedList);
				//console.log(instock);
			}
		};
		getInstockData();
	}, []);
	let inputHandler = (e) => {
		var lowerCase = e.target.value.toLowerCase();
		const filteredData = achieveList.filter((el) => {
			return el.type.toLowerCase().includes(lowerCase);
		});
		if (filteredData.length > 0) setArchieveStock({ filteredData });
		else setArchieveStock({ filteredData });
		return <div>No Results</div>;
	};
	const onFilterTextChange = (e) => {
		var lowerCase = e.target.value;
		setFilterText(lowerCase);
		const filteredData = achieveList.filter((el) => {
			return el.serialNumber.includes(lowerCase);
		});
		console.log(filteredData);

		if (filteredData.length > 0) setArchieveStock(filteredData);
		else setArchieveStock(achieveList);
		return <div>No Results</div>;
	};

	const toggleDrawer = (toggleState) => {
		setState({ ...state, right: toggleState });
	};

	const columns = [
		{
			title: 'Asset Type',
			dataIndex: 'AssetType',
			key: '1',
			width: 150,
		},
		{
			title: 'Asset',
			dataIndex: 'Asset',
			key: '2',
			width: 150,
		},
		{
			title: 'Asset ID',
			dataIndex: 'AssetID',
			key: '3',
			width: 150,
		},
		{
			title: 'Department',
			dataIndex: 'Department',
			key: '4',
			width: 150,
		},
		{
			title: 'Serial Number',
			dataIndex: 'SerialNumber',
			key: '5',
			width: 150,
		},

		...arrayOfChosenOptions
			.filter((el) => el.checked)
			.map((chosenColumn) => {
				return {
					title: chosenColumn.name,
					dataIndex: chosenColumn.mapId,
					key: chosenColumn.mapId,
					width: 150,
				};
			}),
		{
			title: 'Actions',
			dataIndex: 'actions',
			key: '6',
			width: 150,
		},
	];

	const tableData = achieveList.map((el, index) => {
		const health = Array(5)
			.fill()
			.map((v, i) => i);

		return {
			key: index,
			AssetType: el.type,
			Asset: el.name,
			AssetID: el.id,
			Department: el.department,
			SerialNumber: el.serialNumber,
			actions: (
				<BiLinkExternal
					color={'#666666'}
					style={{ cursor: 'pointer' }}
					onClick={() => {
						setSelectedAsset(el);
						toggleDrawer(true);
					}}
				/>
			),
			...arrayOfChosenOptions
				.filter((el) => el.checked)
				.reduce((fields, option) => {
					fields[option.mapId] = el[option.mapId];
					if (option.mapId === 'dateOfAssign') {
						fields[option.mapId] = el.employee?.dateOfAssign;
					}
					if (option.mapId === 'health') {
						fields[option.mapId] = health.map((index) => {
							const color = index < el.healthScore ? '#FDD305' : '#CCCCCC';
							return <AiFillStar key={index} color={color} />;
						});
					}
					if (option.mapId === 'warrantyRemaining') {
						const warrantyRemaining = differenceInDays(new Date(el.warrantyExpiryDate), new Date());
						if (warrantyRemaining > 0) {
							fields[option.mapId] =
								differenceInDays(new Date(el.warrantyExpiryDate), new Date()) + ' Days';
						} else fields[option.mapId] = 'Product Out of Warranty';
					}
					return fields;
				}, {}),
		};
	});

	console.log(achieveList);
	return (
		<div className="dashboard-container">
			<HeaderComponent />
			<div className="management-header">
				<div className="active">Assets Archived</div>
			</div>
			<div className="filter-assets-container">
				<div className="filter-search">
					{/* <div className="filter-search-input">
						<BsSearch />
						<input onChange={onFilterTextChange} placeholder={'Search Asset'} type="text" />
					</div> */}
				</div>
				<div className="filter-buttons-container">
					<FilterAssetsColumn />
				</div>
			</div>
			<div ref={listInnerRef} className="assets-table-container">
				<div className="toggle-drawer">
					<Drawer
						style={{ backgroundColor: '#FAFAFA' }}
						destroyOnClose={true}
						width={1047}
						height={671}
						anchor={'right'}
						open={state['right']}
						onClose={() => toggleDrawer(false)}
					>
						<TemporaryDrawer
							selectedAsset={selectedAsset}
							archievedAsset={true}
							updateAsset={(newAssetInfo) => {
								setSelectedAsset(newAssetInfo);
								dispatch({ type: UPDATE_ASSET_INFO, payload: newAssetInfo });
							}}
						/>
					</Drawer>
				</div>
				<div className="assets-archive-table">
					<Table bordered scroll={{ x: 1300 }} columns={columns} dataSource={tableData} />
				</div>
				{/* <table ref={listInnerRef} className="assetsTable" id="myTable">
					<thead>
						<tr>
							<th>Asset Type</th>
							<th>Asset</th>
							<th>Asset ID</th>
							<th>Department</th>
							<th>Serial Number</th>
							<th>Actions</th>
							{arrayOfChosenOptions
								.filter((el) => el.checked)
								.map((chosenColumn) => {
									return <th>{chosenColumn.name}</th>;
								})}
						</tr>
					</thead>
					<tbody>
						{achieveList.map((el) => {
							const health = Array(5)
								.fill()
								.map((v, i) => i);
							return (
								<tr key={el._id}>
									<td>{el.type}</td>
									<td>{el.name}</td>
									<td>{el.id}</td>
									<td>{el.department}</td>
									<td>{el.serialNumber}</td>
									<td className="each-assets-action" style={{ transform: 'translate(0px,0px)' }}>
										<BiLinkExternal
											color={'#666666'}
											onClick={() => {
												setSelectedAsset(el);
												toggleDrawer(true);
											}}
										/>
									</td>
									{arrayOfChosenOptions
										.filter((el) => el.checked)
										.map((chosenColumn) => {
											if (chosenColumn.mapId === 'dateOfAssign')
												return <td>{el.employee && el.employee.dateOfAssign}</td>;
											if (chosenColumn.mapId === 'health') {
												return (
													<td>
														{health.map((index) => {
															const color =
																index < el.healthScore ? '#FDD305' : '#CCCCCC';
															return <AiFillStar key={index} color={color} />;
														})}
													</td>
												);
											}
											if (chosenColumn.mapId === 'warrantyRemaining') {
												const warrantyRemaining = differenceInDays(
													new Date(el.warrantyExpiryDate),
													new Date()
												);
												if (warrantyRemaining > 0)
													return (
														<td>
															{differenceInDays(
																new Date(el.warrantyExpiryDate),
																new Date()
															)}{' '}
															Days
														</td>
													);
												else return <td>Product Out of Warranty</td>;
											}
											return <td>{el[chosenColumn.mapId]}</td>;
										})}
								</tr>
							);
						})}
					</tbody>
				</table> */}
			</div>
			<FooterDashboard />
		</div>
	);
};

export default ArchieveStockScreen;
