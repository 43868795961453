const itemExist = localStorage.getItem('assetsTableColumns');
let defaultColumnList = [
	{
		name: 'Date of Purchase',
		checked: false,
		mapId: 'dateOfPurchase',
		type: 'date',
	},
	{
		name: 'Invoice ID',
		checked: false,
		mapId: 'invoiceId.invoiceId',
		type: '',
	},
	{
		name: 'Created Date',
		checked: false,
		mapId: 'createdAt',
		type: 'date',
	},
	{
		name: 'Date of Assign',
		checked: false,
		mapId: 'dateOfAssign',
		type: 'date',
	},
	{
		name: 'Price',
		checked: false,
		mapId: 'price',
		type: 'int',
	},
	// {
	// 	name: 'Health',
	// 	checked: true,
	// 	mapId: 'health',
	// 	type: 'array',
	// },
	{
		name: 'Warranty Remaining',
		checked: false,
		mapId: 'warrantyRemaining',
		type: 'date',
	},
	{
		name: 'IMEI',
		checked: false,
		mapId: 'imei',
		type: 'int',
	},
	{
		name: 'Vendor',
		checked: false,
		mapId: 'vendor',
		type: '',
	},
	{
		name: 'Branch ID',
		checked: false,
		mapId: 'officeId.branchId',
		type: '',
	},
	{
		name: 'Employee ID',
		checked: false,
		mapId: 'employee.id',
		type: '',
	},

	// {
	// 	name: 'Asset State',
	// 	checked: false,
	// 	mapId: 'assetState',
	// 	type: '',
	// },
];

let defaultData = [];

if (itemExist) {
	try {
		defaultData = JSON.parse(itemExist);
		for (let i = 0; i < defaultColumnList.length; i++) {
			const isFound = defaultData.find((el) => el.mapId == defaultColumnList[i].mapId);
			if (!isFound) {
				defaultData.push(isFound);
			}
		}
	} catch (e) {
		console.error(e);
	}
}

export const defaultColumns = defaultColumnList;

export function addExtraColums(customKeys, currentColumns) {
	for (let x = 0; x < customKeys.length; x += 1) {
		const colFound = currentColumns.find((el) => el.mapId === customKeys[x].key && el.custom);
		if (!colFound) {
			currentColumns = [
				...currentColumns,
				{
					name: customKeys[x].key,
					checked: false,
					mapId: customKeys[x].key,
					type: '',
					custom: true,
				},
			];
		}
	}
	console.log(currentColumns, 'currentColumns121212');
	return currentColumns;
}
