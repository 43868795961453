import { useState, useEffect, useRef, useCallback } from 'react';
import React from 'react';
import { Input, Select, Form, Drawer, Space, Button, Row, Col, DatePicker, Upload, Modal, Divider } from 'antd';
import { useForm } from 'react-hook-form';
import { download, getApi, postApi, uploadToS3 } from '../../services/api';
import { showSnack } from 'react-redux-snackbar';
import { add } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { sanitizeFileName } from '../../utils/sanitizeFileName.js';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { CheckPermission, EMPLOYEE_SETUP_CREATE } from '../../utils/Permissions.js';
import _, { debounce } from 'lodash';
const { Dragger } = Upload;

const EmployeeModal = ({ refreshEmployees }) => {
	const [assetsList, setAssetsList] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [deptCode, setDeptCode] = useState([]);
	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		defaultValues: {
			name: '',
			empId: '',
			email: '',
			department: '',
			mobile: '',
			dateOfJoining: '',
			devicesProvided: [],
			comments: '',
			unavailableDevices: [],
		},
	});
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		async function getAssetTypes() {
			setAssetsList([]);
			try {
				const resp = await getApi(`/assets/search-assets?assetName=${searchText}&instock=yes`);
				if (resp.type === 'success') {
					console.log(resp?.assets);
					setAssetsList(resp?.assets);
				} else {
					dispatch(
						showSnack('ASSET_SEARCH_ERROR', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} catch (e) {
				dispatch(
					showSnack('ASSET_SEARCH_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			}
		}
		setTimeout(() => {
			if (searchText) {
				getAssetTypes();
			}
		}, 500);
	}, [searchText]);
	console.log(assetsList);

	useEffect(() => {
		const tomorrow = add(new Date(), {
			days: 1,
		});

		setValue('dateOfJoining', tomorrow);
		searchDpt('');
	}, []);
	const dispatch = useDispatch();
	const onSubmit = async (data) => {
		console.log(data);
		// console.log(data.dateOfJoining.$d);
		try {
			console.log(data);
			const selectedDevicesInfo = data.devicesProvided?.map((el) => {
				const asset = assetsList.find((eachAsset) => eachAsset.id == el);
				return {
					_id: asset._id,
					name: asset.name,
					assestId: asset.id,
				};
			});
			const newEmployee = {
				...data,
				dateOfJoining: data?.dateOfJoining.toISOString(),
				devicesProvided: selectedDevicesInfo,
			};
			console.log(newEmployee);
			const resp = await postApi(newEmployee, '/assets/create-employee');
			if (resp.type === 'success') {
				dispatch(
					showSnack('ERROR_MESSAGE', {
						label: 'Successfully Created Employee',
						timeout: 7000,
					})
				);
				setShowModal(false);
				refreshEmployees();
				reset({
					name: '',
					empId: '',
					email: '',
					department: '',
					mobile: '',
					dateOfJoining: '',
					devicesProvided: [],
					comments: '',
					unavailableDevices: [],
				});
				form.resetFields();
			} else {
				dispatch(
					showSnack('ERROR_MESSAGE', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};
	const hiddenFileInput = useRef(null);

	const toggleModal = () => {
		// setOpenModal(!openModal);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const searchDpt = debounce(async (text) => {
		const resp = await getApi(`/assetze/department/search?search=${text}`);
		if (resp.type === 'success') {
			setDeptCode(
				resp.data.map((el) => ({
					label: el.name,
					value: el._id,
				}))
			);
		}
	}, 500);

	const uploadFile = useCallback(
		async (file) => {
			setLoading(true);
			try {
				const resp = await postApi(
					{ filename: file.name, mimetype: file.type },
					'/assets/upload-assets/signedurl'
				);
				if (resp.type === 'success') {
					await uploadToS3(resp.url, file.type, file);
					const serverResp = await postApi({ filename: file.name }, '/assets/upload-assets/readFile');

					if (serverResp.type === 'success') {
						dispatch(
							showSnack('FILE_UPLOAD_SUCCESS', {
								label: 'Successfully Added Assets',
								timeout: 7000,
							})
						);
						toggleModal();
						dispatch({ type: 'RESET_ASSETS_TABLE', payload: Date.now() + '12312' });
					} else if (serverResp.type === 'error') {
						dispatch(
							showSnack('FILE_UPLOAD_ERROR', {
								label: serverResp.error ? serverResp.error : serverResp.message,
								timeout: 7000,
							})
						);
					}
				}
				setLoading(false);
			} catch (e) {
				console.error(e);
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			} finally {
				setLoading(false);
			}
		},
		[dispatch, toggleModal]
	);

	const onDrop = useCallback(
		(acceptedFiles) => {
			console.log(acceptedFiles, 'acceptedFiles');
			if (acceptedFiles.length === 0)
				dispatch(
					showSnack('FILE_UPLOAD_FAILED', {
						label: 'Only Supported XLSX file types',
						timeout: 7000,
					})
				);
			uploadFile(acceptedFiles[0]);
		},
		[dispatch, uploadFile]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
	});

	const downloadFile = (e) => {
		e.preventDefault();
		download(
			'https://assetze.s3.ap-south-1.amazonaws.com/download/Sample-Assetze+template.xlsx',
			'sample-assetze-file.xlsx'
		); //need to change the url and file name
	};

	// const tagRender = (props) => {
	// 	const { label, value, closable, onClose } = props;
	// 	const onPreventMouseDown = (event) => {
	// 		event.preventDefault();
	// 		event.stopPropagation();
	// 	};
	// 	return (
	// 		<Tag
	// 			color={value}
	// 			onMouseDown={onPreventMouseDown}
	// 			closable={closable}
	// 			onClose={onClose}
	// 			style={{
	// 				marginInlineEnd: 4,
	// 			}}
	// 		>
	// 			{label}
	// 		</Tag>
	// 	);
	// };

	const onChange = (value) => {
		console.log(`selected ${value}`);
	};

	const handleSearch = (value) => {
		setSearchText(value);
	};

	const stockOptions = assetsList.map((option) => {
		return {
			label: option.name,
			value: option.id,
			serialNumber: option.serialNumber,
		};
	});
	const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const closeEmpModal = () => {
		setShowModal(false);
		form.resetFields();
	}

	return (
		<>
			<Drawer
				title="Add Employee"
				destroyOnClose={true}
				width={720}
				onClose={() => {
					closeEmpModal();
				}}
				open={showModal}
				styles={{
					body: {
						paddingBottom: 80,
					},
				}}
			// extra={
			// 	<Space className="mobile-hide-btns">
			// 		<Button
			// 			onClick={() => {
			// 				setShowModal(false);
			// 			}}
			// 		>
			// 			Cancel
			// 		</Button>
			// 		<Button htmlType="submit" onClick={() => form.submit()} className="ant-design-button">
			// 			Submit
			// 		</Button>
			// 	</Space>
			// }
			>
				<Form form={form} onFinish={onSubmit} layout="vertical" hideRequiredMark>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="name"
								label="Employee Name"
								rules={[
									{
										required: true,
										message: 'Provide UserName',
									},
								]}
							>
								<Input placeholder="Provide UserName" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="empId"
								label="Employee No."
								rules={[
									{
										required: true,
										message: 'Provide Employee No.',
									},
								]}
							>
								<Input
									style={{
										width: '100%',
									}}
									placeholder="Provide Employee No."
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="email"
								label="Employee Email"
								rules={[
									{
										required: true,
										message: 'Provide Employee Email',
									},
								]}
							>
								<Input
									style={{
										width: '100%',
									}}
									placeholder="Provide Employee Email"
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="department"
								label="Department"
								rules={[
									{
										required: true,
										message: 'Provide Department',
									},
								]}
							>
								<Select
									filterOption={false}
									options={deptCode}
									allowClear
									placeholder="Provide Department"
									onSearch={(val) => {
										searchDpt(val);
									}}
									showSearch
								/>
								{/* <Input
									style={{
										width: '100%',
									}}
									placeholder="Provide Department"
								/> */}
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="dateOfJoining"
								label="Date of Joining"
								rules={[
									{
										required: true,
										message: 'Provide Date',
									},
								]}
							>
								<DatePicker
									placeholder="Provide Date"
									format="YYYY-MM-DD"
									style={{
										width: '100%',
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="mobile"
								label="Mobile Number"
								rules={[
									{
										validator: (_, value) => {
											if (!/^\+\d+$/.test(value)) {
												return Promise.reject(
													new Error(
														'Mobile Number must begin with Country Code followed by 10 digits.'
													)
												);
											}
											return Promise.resolve();
										},
									},
								]}
							>
								<Input
									style={{
										width: '100%',
									}}
									placeholder="Provide Mobile Number"
								/>
							</Form.Item>
						</Col>
					</Row>
					{/* <Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="devicesProvided"
								label="Search Stock Devices"
							>
								<Select
									mode="multiple"
									showSearch
									allowClear
									style={{
										width: '100%',
									}}
									placeholder="Select Devices"
									filterOption={filterOption}
									onChange={onChange}
									onSearch={handleSearch}
									options={stockOptions}
									optionRender={(option) => {
										const imageUrl =
											option.images?.length > 0
												? option.images[0]
												: 'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg1.png';
										return (
											<Space style={{ height: '50px', display: 'flex', gap: '50px' }}>
												<div>
													<img src={imageUrl} style={{ width: '40px', height: '40px' }} />
												</div>
												<div>
													<div>{option.data.label}</div>
													<div>{option.data.value}</div>
												</div>
												<div>
													<div>{option.data.serialNumber}</div>
													<div>8GB 256GB</div>
												</div>
											</Space>
										);
									}}
								></Select>
							</Form.Item>
						</Col>
					</Row> */}
					{/* <Row gutter={16}>
						<Col span={24}>
							<Form.Item name="unavailableDevices" label="Device Name (if not in stock)">
								<Select
									mode="tags"
									style={{
										width: '100%',
									}}
									tokenSeparators={[',']}
								/>
							</Form.Item>
						</Col>
					</Row> */}
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="comments"
								label="Comments"
								rules={[
									{
										message: 'Provide Comment',
									},
								]}
							>
								<Input
									style={{
										width: '100%',
									}}
									placeholder="Provide Comment"
								/>
							</Form.Item>
						</Col>
					</Row>
					<div style={{ margin: '30px 10px', display: 'flex', justifyContent: 'right', gap: '20px' }}>
						<Button
							onClick={() => {
								closeEmpModal();
							}}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							onClick={() => {
								form.submit();
							}}
						>
							Submit
						</Button>
					</div>
					{/* <div className="drawer-mobile-btns">
						<Button
							style={{ height: '40px', width: '100px', borderRadius: '3px' }}
							size="large"
							onClick={() => {
								setShowModal(false);
							}}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							onClick={() => {
								form.submit();
							}}
							size="large"
							className="ant-design-button"
							style={{ height: '40px', width: '100px' }}
						>
							Submit
						</Button>
					</div> */}

					{/* <Divider>Or</Divider>
					<div style={{ padding: '10px 0' }}>Upload employee excel below</div>
					<Row gutter={16}>
						<Col span={24}>
							<MyDropZone
								closeModal={() => {
									closeModal();
								}}
							/>
						</Col>
					</Row> */}
				</Form>
			</Drawer>
			<CheckPermission requiredPermission={EMPLOYEE_SETUP_CREATE}>
				<Button
					size="large"
					className="ant-design-button"
					onClick={() => {
						setShowModal(true);
					}}
				>
					Create Employee
				</Button>
			</CheckPermission>
			<MigrateUserModal />
		</>
	);
};

export default EmployeeModal;


export const EmployeeDrawer = ({ showModal, setShowModal }) => {

	const [form] = Form.useForm();
	const [deptCode, setDeptCode] = useState([]);
	const dispatch = useDispatch();

	const onSubmit = async (data) => {
		console.log(data);
		try {
			console.log(data);
			const newEmployee = {
				...data,
				dateOfJoining: data?.dateOfJoining.toISOString(),
			};
			console.log(newEmployee);
			const resp = await postApi(newEmployee, '/assets/create-employee');
			if (resp.type === 'success') {
				dispatch(
					showSnack('ERROR_MESSAGE', {
						label: 'Successfully Created Employee',
						timeout: 7000,
					})
				);
				setShowModal(false);
				form.resetFields();
			} else {
				dispatch(
					showSnack('ERROR_MESSAGE', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const searchDpt = debounce(async (text) => {
		const resp = await getApi(`/assetze/department/search?search=${text}`);
		if (resp.type === 'success') {
			setDeptCode(
				resp.data.map((el) => ({
					label: el.name,
					value: el._id,
				}))
			);
		}
	}, 500);

	const closeEmpModal = () => {
		setShowModal(false);
		form.resetFields();
	}
	return (
		<Drawer
			title="Add Employee"
			destroyOnClose={true}
			width={720}
			onClose={() => {
				closeEmpModal();
			}}
			open={showModal}
			styles={{
				body: {
					paddingBottom: 80,
				},
			}}
		>
			<Form form={form} onFinish={onSubmit} layout="vertical" hideRequiredMark>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="name"
							label="Employee Name"
							rules={[
								{
									required: true,
									message: 'Provide UserName',
								},
							]}
						>
							<Input placeholder="Provide UserName" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="empId"
							label="Employee No."
							rules={[
								{
									required: true,
									message: 'Provide Employee No.',
								},
							]}
						>
							<Input
								style={{
									width: '100%',
								}}
								placeholder="Provide Employee No."
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="email"
							label="Employee Email"
							rules={[
								{
									required: true,
									message: 'Provide Employee Email',
								},
							]}
						>
							<Input
								style={{
									width: '100%',
								}}
								placeholder="Provide Employee Email"
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="department"
							label="Department"
							rules={[
								{
									required: true,
									message: 'Provide Department',
								},
							]}
						>
							<Select
								filterOption={false}
								options={deptCode}
								allowClear
								placeholder="Provide Department"
								onSearch={(val) => {
									searchDpt(val);
								}}
								showSearch
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="dateOfJoining"
							label="Date of Joining"
							rules={[
								{
									required: true,
									message: 'Provide Date',
								},
							]}
						>
							<DatePicker
								placeholder="Provide Date"
								format="YYYY-MM-DD"
								style={{
									width: '100%',
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="mobile"
							label="Mobile Number"
							rules={[
								{
									validator: (_, value) => {
										if (!/^\+\d+$/.test(value)) {
											return Promise.reject(
												new Error(
													'Mobile Number must begin with Country Code followed by 10 digits.'
												)
											);
										}
										return Promise.resolve();
									},
								},
							]}
						>
							<Input
								style={{
									width: '100%',
								}}
								placeholder="Provide Mobile Number"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="comments"
							label="Comments"
							rules={[
								{
									message: 'Provide Comment',
								},
							]}
						>
							<Input
								style={{
									width: '100%',
								}}
								placeholder="Provide Comment"
							/>
						</Form.Item>
					</Col>
				</Row>
				<div style={{ margin: '30px 10px', display: 'flex', justifyContent: 'right', gap: '20px' }}>
					<Button
						onClick={() => {
							closeEmpModal();
						}}
					>
						Cancel
					</Button>
					<Button
						type="primary"
						onClick={() => {
							form.submit();
						}}
					>
						Submit
					</Button>
				</div>
			</Form>
		</Drawer>
	)
}

const MigrateUserModal = function () {
	const [openModal, setOpenModal] = useState(false);
	const [fromEmpId, setFromEmpId] = useState('');
	const [toEmpId, setToEmpId] = useState('');
	const [page, setPage] = useState(1);
	const [form] = Form.useForm();
	const [employeeInfo, setEmployeeInfo] = useState({
		fromEmpExist: {},
		toEmpExist: {},
	});
	const dispatch = useDispatch();
	useEffect(() => {
		if (!openModal) {
			setPage(1);
		}
	}, [openModal]);

	const confirmMigration = async () => {
		try {
			const resp = await postApi({ fromEmpId, toEmpId }, '/assetze/admin-setup/confirm-migration');
			if (resp.type === 'success') {
				dispatch(
					showSnack('ERROR_MESSAGE', {
						label: 'Successfully migrated assets',
						timeout: 7000,
					})
				);
				setOpenModal(false);
			} else {
				throw new Error(resp.error);
			}
		} catch (e) {
			dispatch(
				showSnack('ERROR_MESSAGE', {
					label: e.message,
					timeout: 7000,
				})
			);
		}
	};
	const checkMigrationRequest = async () => {
		try {
			const resp = await postApi({ fromEmpId, toEmpId }, '/assetze/admin-setup/check-migration-request');
			if (resp.type === 'success') {
				setPage(2);
				setEmployeeInfo({
					fromEmpExist: resp.fromEmpExist,
					toEmpExist: resp.toEmpExist,
				});
				onClose();
				form.resetFields();
			} else {
				throw new Error(resp.error);
			}
		} catch (e) {
			dispatch(
				showSnack('ERROR_MESSAGE', {
					label: e.message,
					timeout: 7000,
				})
			);
		}
	};

	function onClose() {
		setOpenModal(false);
	}
	return (
		<>
			<Drawer
				destroyOnClose={true}
				title="Move device details to another employee"
				width={420}
				onClose={onClose}
				open={openModal}
				styles={{
					body: {
						paddingBottom: 80,
					},
				}}
			>
				<Form form={form} onFinish={checkMigrationRequest} layout="vertical" hideRequiredMark>
					<Col span={17} style={{ margin: 'auto' }}>
						<Form.Item
							name="from"
							label="From"
							rules={[
								{
									required: true,
									message: 'Please enter employeeId',
								},
							]}
						>
							<Input
								value={fromEmpId}
								onChange={(e) => {
									setFromEmpId(e.target.value);
								}}
								type={'text'}
								placeholder={'Employee ID'}
							/>
						</Form.Item>
					</Col>
					<Col span={17} style={{ margin: 'auto' }}>
						<Form.Item
							name="to"
							label="To"
							rules={[
								{
									required: true,
									message: 'Please enter employeeId',
								},
							]}
						>
							<Input
								value={toEmpId}
								type={'text'}
								onChange={(e) => {
									setToEmpId(e.target.value);
								}}
								placeholder={'Employee ID'}
							/>
						</Form.Item>
					</Col>
					<div className="mobile-hide-btns">
						<Col span={17} style={{ margin: 'auto', display: 'flex', justifyContent: 'right' }}>
							<Space>
								<Button onClick={onClose}>Cancel</Button>
								<Button htmlType="submit" className="ant-design-button">
									Submit
								</Button>
							</Space>
						</Col>
					</div>
					<div className="drawer-mobile-btns">
						<Button
							style={{ height: '40px', width: '100px', borderRadius: '3px' }}
							size="large"
							onClick={onClose}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							onClick={() => {
								form.submit();
							}}
							size="large"
							className="ant-design-button"
							style={{ height: '40px', width: '100px' }}
						>
							Submit
						</Button>
					</div>
				</Form>
			</Drawer>
			{/* <Button
				size="large"
				className="ant-design-button"
				style={{ marginLeft: '40px' }}
				onClick={() => setOpenModal(!openModal)}
			>
				Migrate User
			</Button> */}
		</>
	);
};

const MyDropZone = ({ closeModal }) => {
	const [loading, setLoading] = useState(false);
	const hiddenFileInput = useRef(null);
	const [imgFileList, setImgFileList] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();
	const uploadFile = useCallback(
		async (file) => {
			setLoading(true);
			try {
				if (file.size > 1e6) {
					return dispatch(
						showSnack('INVALID_HEALTH', {
							label: 'File Size should be smaller than 1MB',
							timeout: 7000,
						})
					);
				}
				let fileName = sanitizeFileName(file.name);
				const resp = await postApi(
					{ fileName: fileName, mimetype: file.type },
					'/assets/upload-employees/signedurl'
				);
				if (resp.type === 'success') {
					await uploadToS3(resp.url, file.type, file);
					const serverResp = await postApi({ filename: fileName }, '/assets/upload-employees/readFile');
					if (serverResp.type === 'success') {
						dispatch(
							showSnack('FILE_UPLOAD_SUCCESS', {
								label: 'Successfully Added Employees',
								timeout: 7000,
							})
						);
						closeModal();
						dispatch({ type: 'RESET_ASSETS_TABLE', payload: Date.now() + '12312' });
					} else if (serverResp.type === 'error') {
						dispatch(
							showSnack('FILE_UPLOAD_ERROR', {
								label: serverResp.error ? serverResp.error : serverResp.message,
								timeout: 7000,
							})
						);
					}
				}
				setLoading(false);
			} catch (e) {
				console.error(e);
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			} finally {
				setLoading(false);
			}
		},
		[dispatch]
	);

	const onDrop = function (acceptedFiles) {
		if (acceptedFiles.length === 0)
			dispatch(
				showSnack('FILE_UPLOAD_FAILED', {
					label: 'Only Supported XLSX file types',
					timeout: 7000,
				})
			);
		console.log(acceptedFiles, 'acceptedFiles');
		uploadFile(acceptedFiles[0]);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
	});

	const downloadFile = (e) => {
		e.preventDefault();
		download(
			'https://assetze.s3.ap-south-1.amazonaws.com/download/sample-employee.xlsx',
			'sample-employee-file.xlsx' //need to provide static file
		);
	};

	console.log(imgFileList);

	const uploadExcel = async function (file) {
		setLoading(true);
		try {
			let fileName = sanitizeFileName(file.name);
			const resp = await postApi(
				{ fileName: fileName, mimetype: file.type },
				'/assets/upload-employees/signedurl'
			);
			if (resp.type === 'success') {
				await uploadToS3(resp.url, file.type, file.originFileObj);
				const serverResp = await postApi({ filename: fileName }, '/assets/upload-employees/readFile');
				if (serverResp.type === 'success') {
					dispatch(
						showSnack('FILE_UPLOAD_SUCCESS', {
							label: 'Successfully Added Employees',
							timeout: 7000,
						})
					);
					closeModal();
					setShowModal(false);
					dispatch({ type: 'RESET_ASSETS_TABLE', payload: Date.now() + '12312' });
				} else if (serverResp.type === 'error') {
					dispatch(
						showSnack('FILE_UPLOAD_ERROR', {
							label: serverResp.error ? serverResp.error : serverResp.message,
							timeout: 7000,
						})
					);
				}
			}
			setLoading(false);
		} catch (e) {
			console.error(e);
			dispatch(
				showSnack('FILE_UPLOAD_ERROR', {
					label: 'Something went wrong',
					timeout: 7000,
				})
			);
		} finally {
			setLoading(false);
		}
	};

	const props = {
		name: 'file',
		multiple: false,
		onChange: (info) => {
			console.log(info);

			if (info.file.status === 'removed') {
				setImgFileList([]);
			} else {
				setImgFileList(info.fileList);
				setShowModal(true);
			}
		},
		beforeUpload: (file, fileList) => {
			// uploadFile(file);
			if (fileList[0].size > 1e6) {
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: 'File Size should be less than 1MB',
						timeout: 7000,
					})
				);
				return Upload.LIST_IGNORE;
			}

			return false;
		},
	};

	return (
		<>
			<Dragger accept=".xlsx" height={200} maxCount={1} {...props}>
				<p className="ant-upload-drag-icon">
					<IoCloudUploadOutline style={{ fontSize: '30px' }} />
				</p>
				<p className="ant-upload-text">Click or drag to Upload Excel Sheet.</p>
			</Dragger>
			<Modal
				open={showModal}
				onCancel={() => {
					setShowModal(false);
				}}
				title="Submit Excel File"
				onOk={() => {
					uploadExcel(imgFileList[0]);
				}}
				okText="Submit"
				cancelText="Cancel"
			>
				<div>
					<div>Are you sure you want to Submit the file?</div>
				</div>
			</Modal>
			<a
				href="https://assetze.s3.ap-south-1.amazonaws.com/download/sample-employee.xlsx"
				onClick={downloadFile}
				className="dragger-download-template"
			>
				<p>Download Template</p>
			</a>
		</>
	);
};
