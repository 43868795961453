import React from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import './index.css';
import { useDispatch } from 'react-redux';
import { hideLoginModal, verifyToken, loginAction } from '../../redux/actions';
import { AiOutlineEye } from 'react-icons/ai';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { postApi } from '../../services/api';
import { showSnack } from 'react-redux-snackbar';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0px',
		boxShadow: '0 0 5px #7f7f7f',
	},
	overlay: {
		backgroundColor: '#4e4e4e65',
	},
};

const LoginModal = function () {
	const [showPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [view, setView] = useState('login');
	const { showModal } = useSelector((state) => state.appreducer.login);
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		getValues,
	} = useForm();

	const setPassword = async (data) => {
		const resp = await postApi(
			{
				email: data.emailReset,
				type: 'verify-forgotpassword',
				otpId: data.otpId,
				otp: data.otp,
				password: data.passwordNew,
			},
			'/auth/forgotpassword'
		);
		if (resp.type === 'error') {
			return dispatch(
				showSnack('RESET_PASSWORD_SEND_EMAIL_ERROR', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}

		dispatch(
			showSnack('RESET_PASSWORD_SUCCESS', {
				label: 'Successfully reset user password',
				timeout: 7000,
			})
		);

		reset({
			email: '',
			password: '',
			emailReset: '',
			otpId: '',
			otp: '',
			passwordNew: '',
		});
		return setView('login');

		const response = await dispatch(loginAction({ email: data.emailReset, password: data.passwordNew }));
		if (response) {
			dispatch(verifyToken());
			navigate('/dashboard');
			reset({
				email: '',
				password: '',
				emailReset: '',
				otpId: '',
				otp: '',
				passwordNew: '',
			});
		}
	};
	const onSubmitResetEmail = async (data) => {
		const resp = await postApi({ email: data.emailReset, type: 'request-forgotpassword' }, '/auth/forgotpassword');
		if (resp.type === 'error') {
			return dispatch(
				showSnack('RESET_PASSWORD_SEND_EMAIL_ERROR', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}

		setValue('otpId', resp.otpId);
		setView('enter-reset-password');
	};
	const navigate = useNavigate();
	const resendOTP = async (e) => {
		try {
			const values = getValues();
			const resp = await postApi(
				{
					email: values.emailReset,
					type: 'resend-otp',
					otpId: values.otpId,
				},
				'/auth/forgotpassword'
			);
			if (resp.type === 'success') {
				return dispatch(
					showSnack('RESET_PASSWORD_SEND_EMAIL_ERROR', {
						label: 'OTP is sent',
						timeout: 7000,
					})
				);
			}
			dispatch(
				showSnack('RESET_PASSWORD_SEND_EMAIL_ERROR', {
					label: resp.error,
					timeout: 7000,
				})
			);
		} catch (e) {
			dispatch(
				showSnack('RESET_PASSWORD_SEND_EMAIL_ERROR', {
					label: 'Something went wrong',
					timeout: 7000,
				})
			);
		}
	};

	const sendLoginOTP = async (data) => {
		try {
			const resp = await postApi(data, '/auth/login?requestType=sendOTP');
			if (resp.type === 'success') {
				setValue('otpId', resp.data.otpId);
			} else {
				dispatch(
					showSnack('RESET_PASSWORD_SEND_EMAIL_ERROR', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) { }
	};

	const onSubmit = async (data) => {
		console.log('form submit called');

		const fp = await FingerprintJS.load();
		const { visitorId } = await fp.get();

		const resp = await dispatch(loginAction({ ...data, visitorId }));
		if (resp) {
			dispatch(verifyToken());
			navigate('/dashboard');
			reset({
				email: '',
				password: '',
				emailReset: '',
				otpId: '',
				otp: '',
				passwordNew: '',
			});
		}
	};

	console.log('reset-password', view, view === 'reset-password');
	const otpIdExist = getValues('otpId');
	return (
		<>
			<Modal
				isOpen={showModal}
				onRequestClose={() => {
					reset({
						email: '',
						password: '',
						emailReset: '',
						otpId: '',
						otp: '',
						passwordNew: '',
					});
					setView('login');
					dispatch(hideLoginModal());
				}}
				style={customStyles}
				contentLabel="Write Post"
				ariaHideApp={false}
			>
				<input type="hidden" {...register('otpId')} />
				{view === 'enter-reset-password' ? (
					<div className="login-contianer">
						<h1>Enter OTP</h1>
						<p>{window.value}</p>
						<form className="login-form">
							<input
								type={'text'}
								placeholder="User Entered Email"
								{...register('emailReset')}
								disabled
							/>
							<input type={'text'} placeholder="Enter OTP" {...register('otp')} />
							<div className="password">
								{showNewPassword ? (
									<AiOutlineEye
										className="eye-icon"
										onClick={() => setShowNewPassword(!showNewPassword)}
									/>
								) : (
									<AiOutlineEyeInvisible
										className="eye-icon"
										onClick={() => setShowNewPassword(!showNewPassword)}
									/>
								)}
								<input
									type={showNewPassword ? 'text' : 'password'}
									placeholder="Enter new Password"
									{...register('passwordNew')}
								/>
							</div>
							<button onClick={handleSubmit(setPassword)} className="login-button">
								Reset Password
							</button>
							<div className="resend-otp-text" onClick={() => resendOTP()}>
								Resend OTP
							</div>
						</form>
					</div>
				) : null}

				{view === 'reset-password' ? (
					<div className="login-contianer">
						<h1>Forgot Password</h1>
						<form className="login-form">
							<input
								type={'text'}
								id="input-email"
								placeholder="Email ID for Verification"
								{...register('emailReset')}
							/>
							<button className="login-button" onClick={handleSubmit(onSubmitResetEmail)}>
								Send Code
							</button>
							<button
								className="highlight-fp"
								type={'submit'}
								onClick={(e) => {
									e.preventDefault();
									setView('login');
								}}
							>
								Sign in
							</button>
						</form>
					</div>
				) : null}

				{view === 'login' ? (
					<div className="login-contianer">
						<h1>Login</h1>
						<form className="login-form">
							<input
								type={'text'}
								disabled={otpIdExist}
								placeholder="Bussiness Email"
								{...register('email')}
							/>

							{otpIdExist ? (
								<>
									{showPassword ? (
										<AiOutlineEye
											className="eye-icon"
											onClick={() => setShowPassword(!showPassword)}
										/>
									) : (
										<AiOutlineEyeInvisible
											className="eye-icon"
											onClick={() => setShowPassword(!showPassword)}
										/>
									)}
									<input
										type={showPassword ? 'text' : 'password'}
										placeholder="Password"
										{...register('password')}
									/>
									<input type={'text'} placeholder="Provide OTP" {...register('otp')} />
								</>
							) : null}

							<button
								className="highlight-fp"
								onClick={(e) => {
									e.preventDefault();
									setView('reset-password');
								}}
								type="button"
							>
								Forgot Password?
							</button>

							{otpIdExist ? (
								<button
									onClick={handleSubmit(onSubmit)}
									value={'Submit'}
									type="submit"
									className="login-button"
								>
									Login
								</button>
							) : (
								<button
									onClick={handleSubmit(sendLoginOTP)}
									value={'Submit'}
									type="submit"
									className="login-button"
								>
									Send OTP
								</button>
							)}
						</form>
					</div>
				) : null}
			</Modal>
		</>
	);
};

export default LoginModal;
