import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { TwitterPicker } from 'react-color';
import { useDispatch } from 'react-redux';
import { IoCloudUploadOutline } from 'react-icons/io5';
import './tags-creator.css';
import { patchApi, postApi, uploadToS3 } from '../../services/api';
import { triggerShowSnack } from '../../utils/snack';
import { getUserTagInfo } from '../../services/barcode';
import { Input, Upload, Button } from 'antd';
import { getApi } from '../../services/api';
import { assetTagUrl } from '../../services/constants';
import { sanitizeFileName } from '../../utils/sanitizeFileName';
import { ASSET_TAG_SETUP_EDIT, CheckPermission, CheckPermissionCondition } from '../../utils/Permissions';
import { showSnack } from 'react-redux-snackbar';
import { debounce } from 'lodash';
const { Dragger } = Upload;

const popover = {
	position: 'absolute',
	zIndex: '2',
};
const cover = {
	position: 'fixed',
	top: '0px',
	right: '0px',
	bottom: '0px',
	left: '0px',
};

export const MaserAssetTagSetup = () => {
	const [formData, setFormData] = useState({ textColor: 'white', bgColor: '#ff6347' });
	const [logoImage, setLogoImage] = useState(null);
	const [fileInfo, setFileInfo] = useState(null);
	const [showPicker, toggleShowPicker] = useState(false);
	const [assetTag, setAssetTag] = useState('');
	const { TextArea } = Input;
	const dispatch = useDispatch();
	const onDrop = useCallback((acceptedFiles) => {
		// Do something with the files
		console.log(acceptedFiles[0], 'sa');
		setLogoImage(URL.createObjectURL(acceptedFiles[0]));
		setFileInfo(acceptedFiles[0]);
	}, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
	useEffect(() => {
		async function getInfo() {
			const response = await getUserTagInfo();
			if (response && response.type === 'success') {
				setFormData(response.formData);
				setLogoImage(response.logoImage);
				setFormData({ ...response.formData, update: true });
			}
		}
		const getCompany = async function () {
			const resp = await getApi('/users/company');
			if (resp.type === 'success') {
				console.log(resp.company.barcodeInfo);
				setAssetTag(resp.company.assetTag);
				const { address, name, logoUrl, phone, note, bgColor, textColor } = resp.company.barcodeInfo;
				setFormData({
					companyName: name,
					address,
					phone,
					note,
					bgColor,
					textColor,
					update: true,
				});
				setLogoImage(logoUrl);
			}
		};
		getCompany();
		getInfo();
	}, []);

	const handleClose = () => {
		console.log('handleclose');
		toggleShowPicker(!showPicker);
	};
	const handleChange = (color) => {
		console.log('color.hex && color.hex.length === 7', color.hex, color.hex.length === 7);
		if (color.hex && color.hex.length === 7) {
			setFormData({ ...formData, bgColor: color.hex });
			// toggleShowPicker(!showPicker);
		}
	};

	const onSave = debounce(async () => {
		try {
			if (formData.update) {
				if (fileInfo) {
					if (fileInfo.size > 1e6) {
						return dispatch(
							showSnack('INVALID_HEALTH', {
								label: 'File Size should be smaller than 1MB',
								timeout: 7000,
							})
						);
					}
					let fileName = sanitizeFileName(fileInfo.name);
					const resp = await postApi(
						{
							filename: fileName,
							mimetype: fileInfo.type,
						},
						'/assetze/barcode/company-logo/signedurl',
						assetTagUrl
					);
					if (resp.type === 'success') {
						await uploadToS3(resp.url, fileInfo.type, fileInfo);
						const resp2 = await patchApi(
							'/assetze/barcode/tag-info',
							{ updatedParam: 'logoUrl', url: resp.url },
							assetTagUrl
						);
					}
				}
				const { companyName, address, phone, note, bgColor, textColor } = formData;

				if (!companyName) {
					throw new Error('Please Add Company Name');
				}
				if (!address) {
					throw new Error('Please Add Company Address Name');
				}
				if (!phone) {
					throw new Error('Please Company Contact Number');
				}
				if (!note) {
					throw new Error('Please Add Note, For Ex: Laptop is trackable');
				}

				const res = await patchApi(
					'/assetze/barcode/tag-info',
					{
						companyName,
						address,
						phone,
						note,
						bgColor,
						textColor,
						updatedParam: 'all',
					},
					assetTagUrl
				);
				if (res.type === 'success') {
					return triggerShowSnack(dispatch, 'Successfully Updated');
				} else {
					return triggerShowSnack(dispatch, res.error);
				}
			} else {
				if (!fileInfo) {
					throw new Error('Please Add Company Logo to continue');
				}
				const { companyName, address, phone, note, bgColor, textColor } = formData;

				if (!companyName) {
					throw new Error('Please Add Company Name');
				}
				if (!address) {
					throw new Error('Please Add Company Address Name');
				}
				if (!phone) {
					throw new Error('Please Company Contact Number');
				}
				if (!note) {
					throw new Error('Please Add Note, For Ex: Laptop is trackable');
				}

				if (fileInfo.size > 1e6) {
					return dispatch(
						showSnack('INVALID_HEALTH', {
							label: 'File Size should be smaller than 1MB',
							timeout: 7000,
						})
					);
				}
				let fileName = sanitizeFileName(fileInfo.name);
				const resp = await postApi(
					{ filename: fileName, mimetype: fileInfo.type },
					'/assetze/barcode/company-logo/signedurl',
					assetTagUrl
				);
				if (resp.type === 'success') {
					await uploadToS3(resp.url, fileInfo.type, fileInfo);
					const res = await postApi(
						{ companyName, address, phone, note, bgColor, textColor, logourl: resp.url },
						'/assetze/barcode/tag-info',
						assetTagUrl
					);

					if (res.type === 'success') {
						return triggerShowSnack(dispatch, 'Successfully Updated');
					} else {
						return triggerShowSnack(dispatch, res.error);
					}
				}

				return triggerShowSnack(dispatch, resp.error);
			}
		} catch (e) {
			triggerShowSnack(dispatch, e.message);
		}
	}, 2000);

	console.log(showPicker, 'showPicker', formData);

	const props = {
		name: 'file',
		multiple: false,
		onChange(info) {
			const { status } = info.file;
			console.log(status);
			// if (status === 'error') {
			// 	console.log(info.file, info.fileList);
			// 	setLogoImage(URL.createObjectURL(info.file.originFileObj));
			// 	setFileInfo(info.file.originFileObj);
			// }
			if (status === 'removed') {
				setLogoImage('');
				setFileInfo('');
			}
		},
		beforeUpload: (file) => {
			setLogoImage(URL.createObjectURL(file));
			setFileInfo(file);

			return false;
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	return (
		<div className="account-settings">
			{/* <HeaderComponent />
			<div className="account-page-title">
				<div className="account-page-title-container">Account Settings</div>
			</div>
			<AccountTabs /> */}
			<p className="asset-tag-setup-heading">Asset Tag Setup</p>
			<div className="tag-generation-container">
				{/* <div className="input-container">
					<div className="each-input">
						<label for="company-name">Company Name*</label>
						<input
							onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
							id="company-name"
							type={'text'}
							maxLength={30}
							value={formData.companyName}
						/>
					</div>
					<div className="each-input">
						<label for="company-address">Address*</label>
						<input
							onChange={(e) => setFormData({ ...formData, address: e.target.value })}
							id="company-address"
							type={'text'}
							maxLength={100}
							value={formData.address}
						/>
					</div>
					<div className="each-input">
						<label for="contact-number">Contact Number*</label>
						<input
							onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
							id="contact-number"
							type={'text'}
							maxLength={12}
							value={formData.phone}
						/>
					</div>
					<div className="each-input">
						<label for="note">Note*</label>
						<input
							onChange={(e) => setFormData({ ...formData, note: e.target.value })}
							id="note"
							type={'text'}
							maxLength={30}
							value={formData.note}
						/>
					</div>
					<div className="validator">* Upto 30 Characters only</div>
					<div className="tag-logo-drop" {...getRootProps()}>
						<input {...getInputProps()} accept={'image/*'} />
						{isDragActive ? (
							<p className="tag-highlight">uploading.</p>
						) : (
							<p style={{ marginBottom: '0px' }}>
								<span className="tag-highlight">Upload Logo.</span>
								<span className="not-highlish">Or just drag and drop</span>
							</p>
						)}
					</div>
					<div className="validator-note">
						Note: You can download asset tags for all devices or filter few devices on filter section
					</div>
				</div> */}
				<div className="input-container">
					<div className="each-input">
						<label for="company-name">Company Name*</label>
						<Input
							disabled={!CheckPermissionCondition(ASSET_TAG_SETUP_EDIT)}
							onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
							id="company-name"
							type={'text'}
							maxLength={30}
							value={formData.companyName}
						/>
					</div>
					<div className="each-input">
						<label for="company-address">Address*</label>
						<TextArea
							disabled={!CheckPermissionCondition(ASSET_TAG_SETUP_EDIT)}
							onChange={(e) => setFormData({ ...formData, address: e.target.value })}
							id="company-address"
							type={'text'}
							maxLength={100}
							value={formData.address}
						/>
					</div>
					<div className="each-input">
						<label for="contact-number">Contact Number*</label>
						<Input
							disabled={!CheckPermissionCondition(ASSET_TAG_SETUP_EDIT)}
							onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
							id="contact-number"
							type={'text'}
							maxLength={12}
							value={formData.phone}
						/>
					</div>
					<div className="each-input">
						<label for="note">Note*</label>
						<Input
							disabled={!CheckPermissionCondition(ASSET_TAG_SETUP_EDIT)}
							onChange={(e) => setFormData({ ...formData, note: e.target.value })}
							id="note"
							type={'text'}
							maxLength={30}
							value={formData.note}
						/>
					</div>
					<div className="validator">* Upto 30 Characters only</div>
					<div className="dragger-div">
						<Dragger maxCount={1} {...props} disabled={!CheckPermissionCondition(ASSET_TAG_SETUP_EDIT)}>
							<p className="ant-upload-drag-icon">
								<IoCloudUploadOutline />
							</p>
							<p className="ant-upload-text">Click or drag file to this area to upload</p>
						</Dragger>
					</div>
					{/* <div className="tag-logo-drop" {...getRootProps()}>
						<input {...getInputProps()} accept={'image/*'} />
						{isDragActive ? (
							<p className="tag-highlight">uploading.</p>
						) : (
							<p style={{ marginBottom: '0px' }}>
								<span className="tag-highlight">Upload Logo.</span>
								<span className="not-highlish">Or just drag and drop</span>
							</p>
						)}
					</div> */}
					<div className="validator-note">
						Note: You can download asset tags for all devices or filter few devices on filter section
					</div>
				</div>
				<div className="tag-container">
					<div className="tag-content">
						<div className="tag-data">
							<div className="left-tag">
								<img
									className="tag-logo"
									src={logoImage ? logoImage : require('../../images/tags/ast-1.png')}
								/>
								<div className="tag-name">{formData.companyName}</div>
								<div className="tag-address">{formData.address}</div>
								<div className="tag-phone">Ph No:{formData.phone}</div>
								<div className="tag-barcode">
									<img src={require('../../images/tags/barcode.png')} />
								</div>
							</div>
							<div className="right-tag">
								<img src={require('../../images/tags/qr.png')} />
							</div>
						</div>
						<div
							style={{ backgroundColor: formData.bgColor, color: formData.textColor }}
							className="tag-info"
						>
							<div className="tag-id">{assetTag}-176</div>
							<div className="tag-note">*Note: {formData.note}</div>
						</div>
					</div>
					<div className="color-picker-container">
						<div className="color-picker">
							Background Color{'   '}
							<img
								onClick={() => {
									toggleShowPicker(!showPicker);
								}}
								src={require('../../images/tags/picker-1.png')}
							/>
							{showPicker ? (
								<div style={popover}>
									<div style={cover} onClick={() => handleClose()} />
									<TwitterPicker
										color={formData.bgColor}
										triangle={'hide'}
										onChangeComplete={handleChange}
									/>
								</div>
							) : null}
						</div>
						<div className="text-color">
							Text Color {'   '}
							<div
								onClick={() => setFormData({ ...formData, textColor: 'white' })}
								className={formData.textColor === 'white' ? 'white active' : 'white'}
							>
								&nbsp;
							</div>
							<div
								onClick={() => setFormData({ ...formData, textColor: 'black' })}
								className={formData.textColor === 'black' ? 'black active' : 'black'}
							>
								&nbsp;
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="actions">
				<button className="cancel">Cancel</button>
				<button onClick={() => onSave()} className="save">
					Save
				</button>
			</div> */}
			<CheckPermission requiredPermission={ASSET_TAG_SETUP_EDIT}>
				<div style={{ marginTop: '40px', marginBottom: '60px' }} className="drawer-mobile-btns">
					<Button style={{ height: '40px', width: '100px', borderRadius: '3px' }} size="large">
						Cancel
					</Button>
					<Button
						type="primary"
						onClick={() => onSave()}
						size="large"
						className="ant-design-button"
						style={{ height: '40px', width: '100px' }}
					>
						Submit
					</Button>
				</div>

				<div className="mobile-hide-btns">
					<div className="asset-tag-setup-actions">
						<Button className="ant-design-cancel-btn">Cancel</Button>
						<Button onClick={() => onSave()} className="ant-design-save-btn">
							Save
						</Button>
					</div>
				</div>
			</CheckPermission>
		</div>
	);
};
